



































































































































































































import { UtilsNumber } from "@/utils/utils-number";
import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
@Component({
  components: {},
})
export default class view_data_estructura_basica extends Vue {
  @PropSync("estructura") alimento!: any;
  @Prop({ default: 100 }) cantidad!: any;

  public caulcula_dato_proporcional(valor_100g: string, gramos: number) {
    return UtilsNumber.RoudTwoDecimals(
      (Number.parseFloat(UtilsNumber.ValueToNumber(valor_100g).toString()) *
        gramos) /
        100
    );
  }
}
